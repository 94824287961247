<template>
  <div id="app">
     <modify-pwd ref="modifyPwd"></modify-pwd>
    <iframe
      style="border: none; margin-left: 5px"
      :width="searchTableWidth"
      :height="searchTableHeight"
      v-bind:src="reportUrl"
    >
     
  </iframe>
    <!-- <div></div> -->
     <!-- 修改密码 -->
  </div>
</template>
<script>
import modifyPwd from "@/components/modifyPwd.vue";

export default {
  methods: {
    widthHeight() {
      this.searchTableHeight = window.innerHeight - 148;
      this.searchTableWidth = window.innerWidth - 210;
    },
     //  展示更改密码
     showDialog() {
        let pwd= sessionStorage.getItem("pwd")
       if (pwd ==123456) {
         let userId = sessionStorage.getItem("uid");
         console.log(userId)
          this.$refs.modifyPwd.show({ userId: userId });
         console.log(this.$refs.modifyPwd);
       }
     }
  },
  data() {
    return {
      reportUrl: "https://admin.yujian02.xyz/screen",

      searchTableHeight: 0,
      searchTableWidth: 0,
      pwd:'',
    };
  },
  components: {
    modifyPwd
  },
  mounted() {
    window.onresize = () => {
      this.widthHeight(); // 自适应高宽度
    };
    this.$nextTick(function () {
      this.widthHeight();
    });
    this.showDialog();
    
  //  this.showDialog()

  },
  created() {
    
    // 从路由里动态获取 url地址
    this.reportUrl = "https://admin.yujian02.xyz/screen";
    
  },
  watch: {
    $route: function () {
      // 监听路由变化
      this.reportUrl = "https://admin.yujian02.xyz/screen";
    },
  },
  
};
</script>
<style>
#app {
  padding: 0;
}
</style>